import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueAnalytics from "vue-analytics";
import moment from "moment";
import "./plugins";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

Vue.use(VueAnalytics, {
  id: "UA-217844625-2",
  router
});

Vue.use(VuePlyr, {
  plyr: {}
});

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

Vue.prototype.$hub = new Vue();

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
