<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    scrollStop: null,
    sectionObserver: null
  }),
  metaInfo: {
    title: "Plymouth Cares",
    // titleTemplate: '%s | Plymouth Cares',
    titleTemplate: "Plymouth Cares",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  },
  methods: {
    ensureSecure() {
      if (
        window.location.protocol === "http:" &&
        !window.location.host.includes("localhost") &&
        !window.location.host.includes("software")
      ) {
        window.location.href = window.location.href.replace("http:", "https:");
      }
    },
    init() {
      const hash_raw = this.$route.hash;
      const arr = hash_raw.split("?popup=");
      console.log(arr);
      const hash = arr[0];
      const popup = arr.length > 1 ? arr[1] : null;
      this.observeSections();
      if (hash) {
        setTimeout(() => {
          const scrollTo = hash.split("?popup")[0].substr(1);
          this.scrollTo(scrollTo);
          setTimeout(() => {
            this.$hub.$emit("openPdf", popup, hash.split("#").join(""));
          }, 50);
        }, 50);
      }
    },
    scrollTo(id) {
      // console.log("scrollTo", id);
      const EL = document.getElementById(id);
      const RECT = EL.getBoundingClientRect();
      const Y_OFFSET = -40;
      const Y = RECT.top + window.pageYOffset + Y_OFFSET;
      window.scrollTo({ top: Y, behavior: "smooth" });
      if (!this.scrollStop) {
        this.scrollStop = true;
        setTimeout(() => {
          this.scrollTo(id);
        }, 750);
        setTimeout(() => {
          this.scrollTo(id);
        }, 1500);
      }
    },
    observeSections() {
      if (this.sectionObserver) {
        try {
          this.sectionObserver.disconnect();
        } catch (error) {
          console.error(error);
        }
      }

      const options = {
        rootMargin: "0px",
        threshold: 0.1
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".bac-section");
      sections.forEach(section => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        // console.log(entry.isIntersecting, entry.target.id);
        if (entry.isIntersecting) {
          this.respondToRouteChanges = false;
          const sectionId = entry.target.id;
          let routePath = this.$route.path;
          if (sectionId !== "hero") {
            routePath += "#" + sectionId;
          }
          history.pushState({}, null, routePath);
          this.$ga.page(routePath);
        }
      }
    },
    initiateWhenReady() {
      if (!document.getElementById("learn-more")) {
        setTimeout(this.initiateWhenReady, 250);
      } else {
        this.init();
      }
    },
    route(item) {
      let routePath = this.$route.path;
      let id = "#" + item.component;
      if (id !== "#hero") {
        routePath += id;
      }
      console.log(item, routePath, id);
      history.pushState({}, null, routePath);
      this.$ga.page(routePath);
      this.scrollTo(item.component);
    }
  },
  mounted() {
    this.ensureSecure();
    this.initiateWhenReady();
    window.$app = this;
  }
};
</script>

<style lang="scss">
.extra-padding {
  padding-bottom: 96px !important;
  padding-top: 96px !important;
}

@media screen and (max-width: 959px) {
  .extra-padding {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
}
.v-main__wrap {
  background-color: #fafafa !important;
}
</style>
